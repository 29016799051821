<template>
    <div>
        <el-table :data="tabledata">
            <el-table-column label="电表" prop="typeText">
                <template slot-scope="{ row }">
                    <span>{{ row.cardNo?row.cardNo:'-' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="商户" prop="content">
                <template slot-scope="{ row }">
                    <span>{{ row.cardType | initDic(cardTypeDic) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="项目" prop="orderId">
                <template slot-scope="{ row }">
                    <span>￥{{ util.numFormat(row.balance) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="站点" prop="operatorUserPhone">
                <template slot-scope="{ row }">
                    <span>{{ row.maxChargingMinutes }}分钟</span>
                </template>
            </el-table-column>
            <el-table-column label="本次度数" prop="cardNo">
                <template slot-scope="{ row }">
                    <span>{{ row.status ? '暂停使用' : '正常' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="上次度数" prop="cardNo">
                <template slot-scope="{ row }">
                    <span>{{ row.status ? '暂停使用' : '正常' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="本期用电" prop="cardNo">
                <template slot-scope="{ row }">
                    <span>{{ row.status ? '暂停使用' : '正常' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="缴付电费（后缴）" prop="cardNo">
                <template slot-scope="{ row }">
                    <span>{{ row.status ? '暂停使用' : '正常' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="剩余费用（预缴）" prop="cardNo">
                <template slot-scope="{ row }">
                    <span>{{ row.status ? '暂停使用' : '正常' }}</span>
                </template>
            </el-table-column>
            <el-table-column label="预缴电费（预缴）" prop="cardNo">
                <template slot-scope="{ row }">
                    <span>{{ row.status ? '暂停使用' : '正常' }}</span>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    import util from '../../../../src/utils/util'
    export default {
        data() {
            return {
                util: util,
                tabledata: [],
            }
        },
        props:['userId','phone'],
        watch: {
            userId: {
                immediate: true,
                handler (val) {
                    if(val) {
                        this.getTableData()
                    }
                }
            }
        },
        mounted () {
        },
        methods:{
            getTableData () {
                this.$Axios._get({
                    url: this.$Config.apiUrl.userCardListPC,
                    params: {
                        userId: parseInt(this.userId)
                    }
                }).then((res) => {
                    if (res.result.code == 0) {
                        this.tabledata = res.result.data
                    } else {
                        this.$message.error(res.result.msg)
                    }
                }).catch((error) => {
                    this.$message.error(error)
                })
            },
        }
    }
</script>