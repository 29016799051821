<template>
    <div class="app-body">
        <div class="a-flex-rsbc a-ptb-6">
            <bread-crumb></bread-crumb>
            <div class="a-flex-rfsc">
                <el-button type="primary" @click="handleUploadInvoice">上传发票</el-button>
                <el-button type="primary" @click="handleToPay">处理付款</el-button>
                <el-button plain type="danger" @click="handleToReject">驳回</el-button>
                <el-button plain type="danger" @click="submit">撤回</el-button>
                <el-button plain type="danger" @click="submit">关闭单据</el-button>
            </div>
        </div>
        <div class="projectDetail a-flex-cfsfs" style="align-items: stretch;">
            <div class="projectDetail-top a-flex-csbfs ">
                <div class="a-flex-rfsfs">
                    <span class="a-fs-16 a-fw-700">电费单号：9999999999999999</span>
                    <el-tag class="a-ml-08">已驳回</el-tag>
                </div>
                <div class="reject-content a-mt-16 a-flex-cfsfs a-w-100">
                    <span>驳回原因：可视电话挂靠费电话客服等哈个看哈发动机给客户奋达科技很尬</span>
                    <span class="a-mt-10">操作人：陈瑜 15708415426</span>
                </div>
                <div class="a-flex-rfsfs a-flex-wrap a-w-100">
                    <div class="projectDetail-top-item">
                        <div>电费项目</div>
                        <span>{{ detail.createTimeText }}</span>
                    </div>
                    <div class="projectDetail-top-item">
                        <div>收款方</div>
                        <span>{{ detail.lastLoginTimeText }}</span>
                    </div>
                    <div class="projectDetail-top-item">
                        <div>付款当时</div>
                        <span></span>
                    </div>
                    <div class="projectDetail-top-item">
                        <div>发票规则</div>
                        <span></span>
                    </div>
                    <div class="projectDetail-top-item">
                        <div>付款时间</div>
                        <span></span>
                    </div>
                    <div class="projectDetail-top-item">
                        <div>金额</div>
                        <span></span>
                    </div>
                    <div class="projectDetail-top-item">
                        <div>电费单价</div>
                        <span></span>
                    </div>
                    <div class="projectDetail-top-item">
                        <div>当期用电</div>
                        <span></span>
                    </div>
                    <div class="projectDetail-top-item">
                        <div>本次抄表时间</div>
                        <span></span>
                    </div>
                    <div class="projectDetail-top-item">
                        <div>上次抄表时间</div>
                        <span></span>
                    </div>
                    <div class="projectDetail-top-item">
                        <div>标签</div>
                        <span></span>
                    </div>
                    <div class="projectDetail-top-item">
                        <div>电费发票</div>
                        <div class="a-flex-rfsc" style="align-items: stretch" v-if="userFaceInfo">
                            <el-image
                                style="width: 100px; height: 100px; background: #f5f5f5; border-radius: 6px;"
                                :src="userFaceInfo.userFaceUrl"
                                :preview-src-list="[userFaceInfo.userFaceUrl]"
                                fit="contain"></el-image>
                        </div>
                        <span v-else>-</span>
                    </div>
                    <div class="projectDetail-top-item">
                        <div>付款回单</div>
                        <div class="a-flex-rfsc" style="align-items: stretch" v-if="userFaceInfo">
                            <el-image
                                style="width: 100px; height: 100px; background: #f5f5f5; border-radius: 6px;"
                                :src="userFaceInfo.userFaceUrl"
                                :preview-src-list="[userFaceInfo.userFaceUrl]"
                                fit="contain"></el-image>
                        </div>
                        <span v-else>-</span>
                    </div>
                </div>
                <el-tabs class="a-mt-16 devicetabs" v-model="activeName">
                    <el-tab-pane label="电费详情" name="0"></el-tab-pane>
                    <el-tab-pane label="操作记录" name="1"></el-tab-pane>
                    <el-tab-pane label="收款物业" name="2"></el-tab-pane>
                </el-tabs>
            </div>
            <div class="tab-content a-flex-1">
                <electric-charge v-if="activeName == '0'"></electric-charge>
                <operate-list v-if="activeName == '1'"></operate-list>
                <peoperty-info v-if="activeName == '2'"></peoperty-info>
            </div>
        </div>
        <electric-bill-invoice ref='invoice'></electric-bill-invoice>
        <electric-bill-pay ref="payBill"></electric-bill-pay>
        <electric-bill-reject ref="rejectPay"></electric-bill-reject>
    </div>
</template>
<script>

import {
    mapGetters,
    mapActions,
    mapState
} from 'vuex';
import util from '../../../src/utils/util'
import ElectricCharge from './bill-info-child/electric-charge.vue'
import OperateList from './bill-info-child/operate-list.vue';
import PeopertyInfo from './bill-info-child/peoperty-info.vue';
import ElectricBillInvoice from './child/electric-bill-invoice.vue'
import ElectricBillPay from './child/electric-bill-pay.vue'
import electricBillReject from './child/electric-bill-reject.vue'
export default {
    components: { 
        ElectricCharge,
        OperateList,
        PeopertyInfo,
        ElectricBillInvoice,
        ElectricBillPay,
        electricBillReject
    },
    data() {
        
        return {
            util: util,
            activeName: '0',
            id: '',
            detail: {},
        }
    },
    created() {
        this.id = this.$route.query.id
        if(this.id)
        this.getDetail()
    },
    mounted() {

    },
    computed: {
        ...mapState({
            info: state => state.user.user
        })
    },
    methods: {
        getDetail() {
            this.$Axios._get({
                url: this.$Config.apiUrl.getUserManagerDetail,
                params: {
                    userId: parseInt(this.id)
                }
            }).then((res) => {
                if (res.result.code == 0) {
                    this.detail = res.result.data
                    this.userStatInfo = res.result.data.userStatInfo
                    this.userBikeInfo = res.result.data.userBikeInfo
                    this.userFaceInfo = res.result.data.userFaceInfo
                }
            })
        },
        handleUploadInvoice () {
            this.$refs['invoice'].dialogVisible = true
        },
        handleToPay () {
            this.$refs['payBill'].dialogVisible = true
        },
        handleToReject () {
            this.$refs['rejectPay'].dialogVisible = true
        },
    }
}
</script>
<style lang="scss" scoped>
.projectDetail {
    font-size: 14px;
    height: 100%;
    overflow-y: auto;
    
    >div {
        border-radius: 4px;
    }

    &-box {
        border-radius: 4px;
        background: #fff;
    }
    .reject-content{
        background-color: #fef0f0;
        border: 1px solid #fde2e2;
        color: #f56c6c;
        border-radius: 4px;
        padding: 10px;
    }
    &-top {
        background: #fff;
        padding: 24px 24px 0;
        position: relative;
        &-item {
            display: flex;
            flex-direction: row;
            width: 480px;
            margin-top: 16px;
            font-size: 14px;
            line-height: 22px;

            >div:nth-child(1) {
                width: 126px;
                color: #797979;
            }

            span {
                margin-left: 16px;
            }
        }
    }

    .tab-content{
        background: #fff;
        padding: 24px;
        position: relative;
        margin-top: 11px;
    }

    /deep/ .el-tabs__item {
        // color: #007aff;
        font-size: 14px;
    }

    /deep/ .el-tabs__item.is-active {
        color: #007AFF;
        font-weight: bold;
    }

    /deep/ .el-tabs__nav-wrap::after {
        background: none !important;
    }

    /deep/ .el-tabs__header {
        padding: 15px 0 0;
        background: #fff;
    }

    .zdxx {
        background: #fff;
        padding: 24px;

        &-table {
            // margin-top: -24px;
            background: #fff;
            width: 100%;
        }
    }
}



/deep/ .el-date-editor--daterange {
    margin: 0;
    margin-top: -12px;
    margin-bottom: -18px;
}

.zdxx-table {
    padding: 24px;
    background: #fff;
}

/deep/ .projectDetail-top-tabs {
    margin-top: 30px;
}

/deep/ .el-table thead {
    color: #606366 !important;
}

/deep/ .el-table {
    color: #303133
}
</style>